const $ = require('jquery');

import Controller from '../Shared/Controller';
import ShopDOMHelper from './ShopDOMHelper';

const SKELETON_DEFAULT_WIDTH = 190;
const SKELETON_DEFAULT_HEIGHT = 280;

const DEFAULT_ORDER = 'position-rosnaco';
const DEFAULT_CUSTOM_CATEGORY = 'all';
const DEFAULT_PAGE = 1;
const DEFAULT_COUNT = 12;
const DEFAULT_COLORS = null;
const DEFAULT_PATTERN_CATEGORY = null;

export default class ShopController implements Controller {
  private selectors = {
    sortToggle: '[cs-sort-toggle]',
    page: '[cs-page]',
    products: '[cs-products]',
    customCategory: '[cs-custom-category]',
    patternCategory: '[cs-pattern-category]',
    sizeForm: '[cs-size-form]',
    itemForm: '[cs-item-form]',
    buyButton: '[cs-buy-button]',
    cartCount: '[cs-cart-count]',
    productImage: '[cs-product-image]',
    productImageSkeleton: '[cs-product-image-skeleton]',
    filterClear: '[cs-filter-clear]',
    filterColor: '[cs-filter-color]',
    filterPrice: '[cs-filter-price]',
    dialog: {
      size: '[cs-dialog-size]',
      message: '[cs-dialog-message]',
      form: '[cs-dialog-form]',
      continue: '[cs-dialog-continue]',
      cancel: '[cs-dialog-cancel]',
      text: '[cs-dialog-text]',
    },
  };

  private state = {
    count: DEFAULT_COUNT,
    page: DEFAULT_PAGE,
    customCategory: DEFAULT_CUSTOM_CATEGORY,
    custom_category: null,
    order: DEFAULT_ORDER,
    iframe: 0,
    columns: 4,
    filteringActive: false,
    filterMinPrice: null,
    filterMaxPrice: null,
    filterColor: DEFAULT_COLORS,
    filterPatternCategory: DEFAULT_PATTERN_CATEGORY
  };

  init() {
    this.setInitialState();
    this.registerListeners();
  }

  setInitialState() {
    ShopDOMHelper.querySelectorAll(this.selectors.sortToggle).forEach((el) => {
      if (el.classList.contains('active')) {
        this.state.order = el.dataset.sort;
      }
    });
    ShopDOMHelper.querySelectorAll(this.selectors.customCategory).forEach((el) => {
      if (el.classList.contains('active')) {
        this.state.customCategory = el.dataset.customCategory;
      }
    });

    this.state.filteringActive = this.isFilteringActive();
  }

  isFilteringActive() {
    return this.state.order !== DEFAULT_ORDER
      || this.state.customCategory !== DEFAULT_CUSTOM_CATEGORY
      || this.state.filterPatternCategory !== DEFAULT_PATTERN_CATEGORY
      || this.state.filterColor !== DEFAULT_COLORS;
  }

  registerListeners() {
    ShopDOMHelper.querySelectorAll(this.selectors.sortToggle).forEach((el) => {
      el.addEventListener('click', this.sortToggleClickEventHandler.bind(this));
    });
    ShopDOMHelper.querySelectorAll(this.selectors.customCategory).forEach((el) => {
      el.addEventListener('click', this.customCategoryClickEventHandler.bind(this));
    });
    ShopDOMHelper.querySelectorAll(this.selectors.patternCategory).forEach((el) => {
      el.addEventListener('click', this.patternCategoryClickEventHandler.bind(this));
    });
    ShopDOMHelper.querySelectorAll(this.selectors.filterColor).forEach((el) => {
      el.addEventListener('click', this.filterColorClickEventHandler.bind(this));
    });

    document.querySelector(this.selectors.filterClear)
      .addEventListener('click', this.filterClearClickEventHandler.bind(this));

    this.registerPageListeners();
    this.registerProductListeners();
    this.registerImageSkeletonListeners();
  }

  filterClearClickEventHandler(event: Event) {
    (<HTMLElement>event.target).classList.add('hide');

    this.state.customCategory = DEFAULT_CUSTOM_CATEGORY;
    this.state.order = DEFAULT_ORDER;
    this.state.page = DEFAULT_PAGE;
    this.state.filterColor = DEFAULT_COLORS;
    this.state.filterPatternCategory = DEFAULT_PATTERN_CATEGORY;

    ShopDOMHelper.querySelectorAll(this.selectors.filterColor).forEach((el: HTMLInputElement) => {
      el.parentElement.parentElement.classList.remove('active');
    });

    ShopDOMHelper.querySelectorAll(this.selectors.customCategory).forEach((el) => {
      if (el.dataset.customCategory === this.state.customCategory) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });

    ShopDOMHelper.querySelectorAll(this.selectors.patternCategory).forEach((el) => {
      if ((el.dataset.id === 'null' && this.state.filterPatternCategory === null)
        || el.dataset.id === this.state.filterPatternCategory) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });

    ShopDOMHelper.querySelectorAll(this.selectors.sortToggle).forEach((el) => {
      if (el.dataset.sort === this.state.order) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });

    this.state.filteringActive = this.isFilteringActive();
    this.loadProducts();
  }

  registerImageSkeletonListeners() {
    const self = this;

    ShopDOMHelper.querySelectorAll(this.selectors.productImage).forEach((el: HTMLElement) => {
      const skeleton = el.parentElement.parentElement.querySelector(self.selectors.productImageSkeleton);

      if (skeleton.classList.contains('productThumb__skeleton--hide')) {
        return;
      }

      const skeletonChild: HTMLElement = <HTMLElement>skeleton.children[0];
      const skeletonChildHeight = parseInt(skeletonChild.style.height, 10);
      const skeletonChildWidth = parseInt(skeletonChild.style.width, 10);

      const scaleSkeleton = skeleton.parentElement.clientWidth / skeletonChildWidth;
      const skeletonHeight = Number(skeletonChild.clientHeight * scaleSkeleton).toFixed(1);

      skeleton.setAttribute('style', `height: ${skeletonChildHeight}px`);
      skeleton.setAttribute('style', `height: ${skeletonHeight}px`);

      const image = new Image();

      image.onload = () => {
        const scale = skeleton.parentElement.clientWidth / image.naturalWidth;
        const newHeight = Number(image.naturalHeight * scale).toFixed(1);
        skeleton.setAttribute('style', `height: ${newHeight}px; color: red;`);

        setTimeout(
          () => {
            skeleton.classList.add('productThumb__skeleton--hide');
            el.parentElement.classList.remove('productThumb__product--absolute');
          }, 300
        );
      };

      image.src = el.getAttribute('src');
    });
  }

  registerPageListeners() {
    ShopDOMHelper.querySelectorAll(this.selectors.page).forEach((el) => {
      el.addEventListener('click', this.pageClickEventHandler.bind(this));
    });
  }

  registerProductListeners() {
    ShopDOMHelper.querySelectorAll(this.selectors.buyButton).forEach((el) => {
      el.addEventListener('click', this.buyButtonClickEventHandler.bind(this));
    });
  }

  buyButtonClickEventHandler(event: Event) {
    event.preventDefault();
    event.stopPropagation();

    const productContainer = (<HTMLElement>event.currentTarget).parentElement.parentElement;
    const sizeForm = <HTMLFormElement>productContainer.querySelector(this.selectors.sizeForm);
    const itemForm = <HTMLFormElement>productContainer.querySelector(this.selectors.itemForm);

    const id = (<HTMLInputElement>itemForm.querySelector('[name="id"]')).value;
    const qty = (<HTMLInputElement>itemForm.querySelector('[name="quantity"]')).value;
    const size = (<HTMLInputElement>sizeForm.querySelector('[name="size"]')).value;

    console.log({sizeForm, itemForm});
    console.log({id, qty, size, length: sizeForm.length});

    if (sizeForm.length === 1 && size) {
      this.addToCart(id, size, Number(qty));
    } else {
      this.createSizeDialog(sizeForm).then((sizeElement: HTMLElement) => {
        const { dataset } = sizeElement;
        this.addToCart(id, dataset.id, Number(qty));

      }, () => {
        console.log('fail');
      });
    }

    (<HTMLElement>event.currentTarget).blur();
  }

  patternCategoryClickEventHandler(event) {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const { id } = dataset;

    ShopDOMHelper.querySelectorAll(this.selectors.patternCategory).forEach((el) => {
      if (el.dataset.id === id) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });

    this.state.filterPatternCategory = id === 'null' ? null : id;
    this.state.filteringActive = this.isFilteringActive();
    this.state.page = DEFAULT_PAGE;
    this.updateFilteringClearButton();

    this.loadProducts();
  }

  customCategoryClickEventHandler(event) {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const { customCategory } = dataset;

    ShopDOMHelper.querySelectorAll(this.selectors.customCategory).forEach((el) => {
      if (el.dataset.customCategory === customCategory) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });

    this.state.customCategory = customCategory;
    this.state.filteringActive = this.isFilteringActive();
    this.state.page = DEFAULT_PAGE;
    this.updateFilteringClearButton();

    this.loadProducts();
  }

  pageClickEventHandler(event) {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const { page } = dataset;

    this.state.page = page;

    this.loadProducts();
  }

  sortToggleClickEventHandler(event) {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const { sort } = dataset;

    ShopDOMHelper.querySelectorAll(this.selectors.sortToggle).forEach((el) => {
      if (el.dataset.sort === sort) {
        el.classList.add('active');
      } else {
        el.classList.remove('active');
      }
    });

    event.currentTarget.classList.add('active');
    this.state.order = sort;
    this.state.filteringActive = this.isFilteringActive();
    this.state.page = DEFAULT_PAGE;
    this.updateFilteringClearButton();

    this.loadProducts();
  }

  filterColorClickEventHandler(event) {
    event.preventDefault();

    const { dataset } = event.currentTarget;
    const { sort } = dataset;

    const colors = [];

    event.currentTarget.parentElement.parentElement.classList.toggle('active');

    ShopDOMHelper.querySelectorAll(this.selectors.filterColor).forEach((el: HTMLInputElement) => {
      if (el.parentElement.parentElement.classList.contains('active')) {
        colors.push(el.value);
      }
    });

    this.state.filterColor = colors.length ? colors : null;

    this.state.filteringActive = this.isFilteringActive();
    this.updateFilteringClearButton();

    this.loadProducts();
  }

  loadProductsSkeleton() {
    let html = `<div class="row">`;

    const existingImage = ShopDOMHelper.querySelector(this.selectors.productImage);
    const width = existingImage ? existingImage.clientWidth : SKELETON_DEFAULT_WIDTH;
    const height = existingImage ? existingImage.clientHeight : SKELETON_DEFAULT_HEIGHT;

    for (let i = 0; i < 12; i++) {
      html += `
      <div class="col-lg-3 col-md-4 col-sm-6 col-xs-12">
        <a href="#" class="productThumb">
          <div class="productThumb__skeleton" cs-product-image-skeleton><div style="width: ${width}px; height: ${height}px;"></div></div>
          <div class="productThumb__title-skeleton"></div>
          <div class="price-skeleton"></div>
        </a>
      </div>`;
    }

    html += `</div>`;
    document.querySelector(this.selectors.products).innerHTML = html;
  }

  loadProducts() {
    const self = this;
    const state = Object.assign({}, this.state);

    state.custom_category = state.customCategory;

    self.loadProductsSkeleton();

    $.ajax({
      url: '/shop/ajax',
      type: 'post',
      data: state,
      dataType: 'json',
      success: function (response) {
        if (response.status == 'success') {
          document.querySelector(self.selectors.products).innerHTML = response.items;
          self.registerPageListeners();
          self.registerImageSkeletonListeners();
        } else {
          console.log(response.msg);
        }
      },
    });
  }

  addToCart(productId, size, quantity = 1) {
    const self = this;

    $.ajax({
      url: '/cart/ajax-item-add?sec_code=' + this.getZendVars().sec_code,
      type: 'post',
      data: {
        id: productId,
        quantity: quantity,
        size: size,
        iframe: this.getZendVars().iframe
      },
      dataType: 'json',
      success: function (response) {
        if (response.status == 'success') {
          console.log(response);

          const responseHtml = document.createElement('div');
          responseHtml.innerHTML = response.dropdown;
          const cartCount = (<HTMLElement>responseHtml.querySelector('.prod_count')).innerText;

          ShopDOMHelper.querySelector(self.selectors.cartCount).classList.remove('hide');
          ShopDOMHelper.querySelector(self.selectors.cartCount).innerText = cartCount;

          const textHtml = document.createElement('p');
          textHtml.innerHTML = response.msg;
          self.createMessageDialog(textHtml).then(() => {});
        }
        console.log(response);
      },
    });
  }

  getZendVars() {
    return (<any>window).zend_vars;
  }

  createMessageDialog(html: HTMLElement): Promise<any> {
    const dialog = <HTMLElement>document.querySelector(this.selectors.dialog.message).cloneNode(true);
    dialog.querySelector(this.selectors.dialog.text).appendChild(html);

    const self = this;

    return new Promise((resolve, reject) => {

      document.body.appendChild(dialog);
      dialog.classList.remove('hide');
      dialog.classList.remove('fade');

      dialog.querySelector(self.selectors.dialog.continue).addEventListener('click', (event: Event) => {
        event.preventDefault();

        dialog.classList.add('fade');
        resolve();
        setTimeout(() => {
          dialog.classList.add('hide');
          dialog.remove();
        }, 200);
      });
    });
  }

  createSizeDialog(sizeForm: HTMLFormElement): Promise<HTMLElement> {
    const dialog = <HTMLElement>document.querySelector(this.selectors.dialog.size).cloneNode(true);
    const dialogForm = dialog.querySelector('.dialog__form');

    const self = this;

    Array.from(sizeForm.children).forEach((el: HTMLInputElement) => {
      const newElement = document.createElement('a');

      newElement.href = '#';
      newElement.dataset.id = el.value;
      newElement.innerText = el.dataset.size;
      newElement.classList.add('size');

      dialogForm.appendChild(newElement);
    });

    return new Promise((resolve, reject) => {

      document.body.appendChild(dialog);
      dialog.classList.remove('hide');
      dialog.classList.remove('fade');

      const sizes = Array.from(dialog.querySelectorAll('.size'));

      sizes.forEach((el: HTMLElement) => {
        el.addEventListener('click', (event: Event) => {
          event.preventDefault();

          sizes.forEach(el => el.classList.remove('active'));
          (<HTMLElement>event.currentTarget).classList.add('active');
          dialog.querySelector(self.selectors.dialog.continue).classList.remove('disabled');
        });
      });


      dialog.querySelector(self.selectors.dialog.cancel).addEventListener('click', (event: Event) => {
        event.preventDefault();

        if ((<HTMLElement>event.currentTarget).classList.contains('disabled')) {
          return;
        }

        dialog.classList.add('fade');
        reject('cancel');
        setTimeout(() => {
          dialog.classList.add('hide');
          dialog.remove();
        }, 200);
      });
      dialog.querySelector(self.selectors.dialog.continue).addEventListener('click', (event: Event) => {
        event.preventDefault();

        dialog.classList.add('fade');
        resolve(<HTMLElement>dialog.querySelector('.size.active'));
        setTimeout(() => {
          dialog.classList.add('hide');
          dialog.remove();
        }, 200);
      });
    });
  }

  updateFilteringClearButton() {
    const el = <HTMLElement>document.querySelector(this.selectors.filterClear);

    if (this.state.filteringActive) {
      el.classList.remove('hide');
    } else {
      el.classList.add('hide');
    }
  }
}
