const $ = require('jquery');
const bootstrap = require('../node_modules/bootstrap/dist/js/bootstrap.bundle.js');
(<any>window).$ = $;

// import * as bootstrap from 'bootstrap';
(<any>window).bootstrap = bootstrap;
import './shop.scss';

import 'babel-polyfill';
import ShopController from './Shop/ShopController';

(<any>window).csLegacyApp = {
  shop: new ShopController(),
};

document.addEventListener('DOMContentLoaded', () => {
  (<any>window).csLegacyApp.shop.init();

  $('html').on('click', '.show-menu', function () {
    $('.main-menu').toggleClass('open');
    $('#show-menu').toggleClass('open');
    $('.filters').toggleClass('open');
    $('.filters').toggleClass('fadeInRight');
    $('body').toggleClass('mask');
  });

  $(".collapseOpen").click(function () {
    var status = $(this).attr("data-status");
    $(this).toggleClass("closed");
    if (status == 1) {
      $(this).attr("data-status", 0);
    } else {
      $(this).attr("data-status", 1);
    }
  });

  $(window).on('resize load', function () {
    var size = $(window).width();
    console.log(size);
    if ($(window).width() < 992) {
      var status = $(this).attr("data-status");
      $(".collapseOpen").addClass("collapsed closed");
      $(".collapseOpen").attr("data-status", 0);
      $(".collapseOpen").attr("aria-expanded", false);
      $(".collapseOpen").attr("data-toggle", "collapse");
      $(".collapse").removeClass("show");
      $(".collapseOpen").addClass("active");

    } else {
      var status = $(this).attr("data-status");
      $(".collapseOpen").removeClass("collapsed closed");
      $(".collapseOpen").attr("data-status", 1);
      $(".collapseOpen").attr("aria-expanded", true);
      $(".collapseOpen").attr("data-toggle", "");
      $(".collapse").addClass("show");
      $(".collapseOpen").removeClass("active");
    }
  });
  //
  // $(".preview").click(function () {
  //   $(this).parent().parent().toggleClass("active");
  // });
});
//
// document.addEventListener('InitShop', () => {
//   (<any>window).csApp.shop.init();
// });
